import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import './rush.scss';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

// Import the events JSON
import events from '../../data/Fall2024RushEvents.json';
import privEvents from '../../data/Fall2024Priv.json';


import springBreak2023 from '../assets/images/slideshow/springBreak2023.jpg';
import ringDel2023_1 from '../assets/images/slideshow/ringDel2023.jpg';
import ringDel2023_2 from '../assets/images/slideshow/ringDel2023_2.jpg';
import canInitSpring2023 from '../assets/images/slideshow/brotherInit2023.jpg';
import retreatSpring2023 from '../assets/images/slideshow/retreatSpring2023.jpg';

const SLIDESHOW_IMAGES = [
  { src: springBreak2023, legend: 'Cute bros hanging out in Puerto Rico.' },
  { src: ringDel2023_1, legend: 'Brothers presenting their ring.' },
  { src: ringDel2023_2, legend: 'Look at those rings!' },
  { src: canInitSpring2023, legend: 'Newly initiated brothers!' },
  { src: retreatSpring2023, legend: 'Spring Retreat 2023' },
];

const INTEREST_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdVVh6aGsN6S_oca1_NKvz-H5EcHc0u1PjxMED9UXeXQrkhjw/viewform';
const IFC_SIGMA_NU_RUSH_SCHEDULE =
  'https://mitrush2023.sched.com/overview/venue/Sigma+Nu/99+Bay+State+Rd%2C+Boston%2C+MA';

class Rush extends React.Component {
  constructor(props) {
    super(props);
  }

  formatEventTime(startDate, endTime) {
    // Format the date and time as "Date: 09/01, 6-8 PM"
    const options = { month: '2-digit', day: '2-digit' };
    const startDateTime = new Date(startDate).toLocaleDateString(undefined, options);
    const startTime = new Date(startDate).toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
    const endTimeFormatted = new Date(endTime).toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
    return `Date: ${startDateTime}, ${startTime}-${endTimeFormatted}`;
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Rush</title>
          <meta name="description" content="Rush Page" />
        </Helmet>

        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>2024 Fall Rush</h1>
                <p>Fill out our interest form to keep in contact!</p>
              </header>
              <div>
                <button>
                  <a href={INTEREST_FORM_LINK} target="_blank">
                    Interest Form
                  </a>
                </button>
                &nbsp;
                <button>
                  <a href={'#contact'}> Contact info</a>
                </button>
                &nbsp;
              </div>
              <div style={{ margin: '32px' }}></div>

              <header className="major">
                <h2>EVENTS (Pre-Kickoff)</h2>
              </header>

              <p align="left">
                {' '}
                All events are also on the{' '}
                <a href={"https://drive.google.com/thumbnail?id=1j6XaZm95SvE5DjxTrsRSseM2LcouQ1ZA&sz=w800"} target="_blank">
                  IFC schedule
                </a>{' '}
              </p>

              <div className="flex flex-col">
                {privEvents.map((event, index) => (
                  <div key={index} className="event-card">
                    <h3>{event.title}</h3>
                    <p>{this.formatEventTime(event.start_date, event.end_time)}</p>
                    <p>Venue: {event.venue}</p>
                    <p>Address: {event.address}</p> {/* Ensure your JSON includes the address field */}
                    <p>Type: {event.type}</p>
                  </div>
                ))}
              </div>
              <h3 style={{ textAlign: 'center', marginTop: '20px', fontWeight: 'bold' }}>ALL EVENTS ANNOUNCED POST-KICKOFF</h3>


              <div className="flex flex-col">
                <img src={"https://drive.google.com/thumbnail?id=1j6XaZm95SvE5DjxTrsRSseM2LcouQ1ZA&sz=w800"} />
              </div>

              <header className="major">
                <h2>Pictures</h2>
              </header>
              <div className={'slideshow'}>
                <Carousel
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={true}
                  interval={3000}
                  showArrows={false}
                >
                  {SLIDESHOW_IMAGES.map((item, index) => (
                    <div key={index}>
                      <img src={item.src} alt={index} className={'image'} />
                      <p className="legend">{item.legend}</p>
                    </div>
                  ))}
                </Carousel>
              </div>

              <p>Note from the Rush Chairs,</p>
              <p>
                Sigma Nu is much more than a fraternity; it is a group of loving
                brothers who push each other to be the best they can be. Our
                beautiful home is filled with rich camaraderie, hysterical
                inside jokes, and ever-lasting memories that brothers have come
                to cherish for a lifetime. Late night journeys to 7-Eleven,
                Saturday night half-off burgers at Pour House, and playing Smash
                Bros in the Den are just some of the many traditions brothers of
                Sigma Nu have come to love!
              </p>
              <p>
                Brothers take active roles on campus, becoming leaders and being
                involved with various clubs. In addition, brothers at Sigma Nu
                are driven to succeed at the highest academic level and have
                interned at premier companies such as the Jet Propulsion Lab,
                Google, Chevron, Microsoft, Twitter, and Facebook. Our alumni
                have gone on to work at Snapchat, Facebook, Microsoft, GE, BCG
                or have continued their academic careers in prestigious PhD
                programs at Stanford and MIT.
              </p>
              <p>
                We have members who come from all around the world with a wide
                variety of interests: music, world cultures, athletics, video
                games, community work, and more. Brothers are always willing to
                explore new things and meet new people, allowing our fraternity
                to bolster with interesting conversations and explorations.
                These explorations forge life-long bonds of brotherhood and
                friendship at Sigma Nu.
              </p>
              <p>Fraternally,</p>
              <p>Sigma Nu Epsilon Theta Recruitment Chairs</p>

              <header className="major" id="contact">
                <h2>Contact Info</h2>
              </header>
              <p>
                Rush starts  08/31! Fill out our{' '}
                <a href={INTEREST_FORM_LINK} target="_blank">
                  Interest Form!{' '}
                </a>
              </p>
              <p>
                Follow us on{' '}
                <a href={'https://instagram.com/mitsigmanu'} target="_blank">
                  Instagram
                </a>{' '}
                and check out our{' '}
                <a href={'https://facebook.com/MIT.SigmaNu'} target="_blank">
                  {' '}
                  Facebook!
                </a>
              </p>
              <p>
                <h3>Contact our rush team</h3>
                <section style={{ display: 'flex', justifyContent: 'center' }}>
                  <ContactCard
                    bro={{ name: 'Russell Perez', number: '561-774-0484' }}
                  ></ContactCard>
                  <ContactCard
                    bro={{ name: 'Brios Olivares', number: '917-514-4271'}}
                  ></ContactCard>
                </section>
              </p>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

class ContactCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <section style={{ padding: '0px 20px' }}>
        <a href={'tel:' + this.props.bro.number}>{this.props.bro.number}</a>
        <h4>{this.props.bro.name}</h4>
      </section>
    );
  }
}

export default Rush;